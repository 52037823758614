import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Testimonials } from "./components/testimonials";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import landerdata from "./data/landerdata.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Chatbot from './components/chatbot';
import { Navigation1 } from "./lander/navigation";
import { Header1 } from "./lander/header";
import { Features1 } from "./lander/features";
import { About1 } from "./lander/about";
import { Services1 } from "./lander/services";
import { Gallery1 } from "./lander/gallery";
import { Testimonials1 } from "./lander/testimonials";
import { Team1 } from "./lander/Team";
import { Contact1 } from "./lander/contact";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

const App = () => {
  const [landingPageData, setLandingPageData] = useState(JsonData);
  const [landingPageData1, setLandingPageData1] = useState(landerdata);
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route path="/uif" element={<Chatbot />} />
        <Route path="/chatbot" element={
          <>
            <Navigation1 data={landingPageData1} />
            <Header1 data={landingPageData1.Header} />
            <Features1 data={landingPageData1.Features} />
            <About1 data={landingPageData1.About} />
            <Services1 data={landingPageData1.Services} />
            <Gallery1 data={landingPageData1.Gallery} />
            <Testimonials1 data={landingPageData1.Testimonials} />
            <Team1 data={landingPageData1.Team} />
            <Contact1 data={landingPageData1.Contact} />
          </>
        } />
        <Route path="/" element={
          <>
            <Navigation data={landingPageData} />
            <Header data={landingPageData.Header} />
            <Features data={landingPageData.Features} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} />
          </>
        } />
      </Routes>
    </>
  );
};

export default AppWrapper;